@import url('https://fonts.googleapis.com/css?family=Montserrat');

.rowContainerEvenly {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.rowContainerCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rowContainerBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.alignCenterInContainer {
    align-items: center;
}

.rowContainerEnd {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.rowContainerStart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.columnContainerBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.columnContainerStart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.columnContainerCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.columnContainerEvenly {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

hr {
    border-color: var(--sft_blue);
    width: 100%;
}

.textareaBorderRight {
    border-right: 1px grey solid;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
}

.textareaBorderLeft {
    border-left: 1px grey solid;
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
}

.maxHeight {
    height: 100%;
}

.height_75 {
    height: 75px !important;
}

.button_container__button_no_margin {
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
}

.button_container__button_no_margin:hover {
    background-color: var(--sft_blue_darker) !important;
    color: var(--sft_blue_text) !important;
}

.sftBorder {
    border-color: var(--sft_blue);
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    padding: 5px;
}

.sftBorderBottom {
    border-bottom: 2px solid var(--sft_blue);
}

.textAlignLeft {
    text-align: start;
}

.styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    max-height: 485px;
    min-height: 100px;
    overflow:auto;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
    color: black;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid var(--sft_blue);
}

.noBottomMargin {
    margin-bottom: 0px !important;
}

