.login__ {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
}

.login__logo {
    width: 300px;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login__form {
    border: 0px;  
}
.login__form input {
    width: 100%;
    font-size: 15px;
}

.login__buttons{
    height: 70px;
}

.login__button {
    width: 100%;
    color: var(--sft_blue) !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.login_forgotpass__button_dark {
    width: 100%;
    color: var(--dark_text) !important;
    font-size: 10px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    height: 20px !important;
}

.login_forgotpass__button_light {
    width: 100%;
    color: var(--light_text) !important;
    font-size: 10px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    height: 20px !important;
}

/**********************************************************************************
*   Tablet layout
**********************************************************************************/
@media only screen and (min-width: 600px) {
    .login__ {
        position: absolute;
        top:50%;       
    }

    .login__form {
        width: 80%;
        border: 0px;
        margin: auto;    
        border-radius: 6px;
    }

    .login__logo {
        width: 350px;        
        margin-top: 30px;
    }
}

/**********************************************************************************
*   Desktop layout
**********************************************************************************/
@media only screen and (min-width: 1200px) {
    .login__ {
        position: absolute;
        top:50%;       
    }

    .login__form {
        width: 30%;
        border: 0px;
        margin: auto;    
        border-radius: 6px;
    }

    .login__logo {
        width: 400px;
    }

    .login__form_dark {
        -webkit-box-shadow: 0px 0px 5px 2px var(--dark_accent_darker);
        box-shadow: 0px 0px 5px 2px var(--dark_accent_darker);
    }

    .login__form_light {        
        -webkit-box-shadow: 0px 0px 5px 2px var(--light_accent_darkest);
        box-shadow: 0px 0px 5px 2px var(--light_accent_darkest);
    }
}