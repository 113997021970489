
  .header {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-left: 3px;
  }
  
  .toggle-text {
    font-size: 16px; /* Smaller text size */
    margin-right: 10px; /* Space between text and arrow */
  }
  
  .arrow {
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    transition: transform 0.3s ease;
  }
  
  .arrow.right {
    border-top: 6px solid var(--sft_blue);
    transform: rotate(270deg);
  }
  
  .arrow.down {
    border-top: 6px solid var(--sft_blue);
    
  }
  
  .details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, padding 0.5s ease;
    padding: 0 10px;
  }
  
  .details.show {
    max-height: 500px; /* Adjust based on content */
    padding: 10px;
  }
  
  .details p {
    margin: 10px 0;
  }
  
  .table_rma_head_width {
    width: 200px;
    vertical-align: top;
  }
  
  .rma_content_block {
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 315px;
  }

  .small_margin_bottom {
    margin-bottom: 10px !important;
  }