.licence__enivronment .MuiBox-root {
    padding: 0px !important;
}

.lic_button_container{
    width: 250px;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: 10px;    
}

.lic_info_container {
    width: 80%;
    /*padding: 5px;*/
    font-size: 15px;
    align-content: center;
    align-items: center;
    justify-content: center;
}