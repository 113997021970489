$globe-width:   12px;
$globe-height:  28px;
$globe-spacing: 40px;
$globe-spread: 3px;
$light-off-opacity: 0.4;

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: 45px 0 0 140px;
  padding: 0;
  pointer-events: none;
  width: 100%;
  li {
    position: relative;
    animation-fill-mode: both; 
    animation-iteration-count:infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: $globe-width;
    height: $globe-height;
    border-radius: 50%;
    margin: calc($globe-spacing / 2);
    display: inline-block;
    background: rgba(0,247,165,1);
    box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(0,247,165,1);
    animation-name: flash-1;
    animation-duration: 2s;
    &:nth-child(2n+1) {
      background: rgba(0,255,255,1);
      box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(0,255,255,0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }
    &:nth-child(4n+2) {
      background: rgba(247,0,148,1);
      box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(247,0,148,1);
      animation-name: flash-3;
      animation-duration: 1.1s;
    }
    &:nth-child(odd) {
      animation-duration: 1.8s;
    }
    &:nth-child(3n+1) {
      animation-duration: 1.4s;
    }
    &:before {
      content: "";
      position: absolute;
      background: #222;
      width: ($globe-width - 2);
      height: calc($globe-height / 3);
      border-radius: 3px;
      top: (0 - (calc($globe-height / 6)));
      left: 1px;
    }
    &:after {
      content: "";
      top: (0 - calc($globe-height / 2));
      left: $globe-width - 3;
      position: absolute;
      width: $globe-spacing + 12;
      height: (calc($globe-height / 3) * 2);
      border-bottom: solid #222 2px;
      border-radius: 50%;
    }
    &:last-child:after {
      content: none;
    }
    &:first-child {
      margin-left: -$globe-spacing;
    }
  }
}
@keyframes flash-1 { 
    0%, 100% { background: rgba(0,247,165,1);
    box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(0,247,165,1);} 
    50% { background: rgba(0,247,165,$light-off-opacity);
    box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(0,247,165,0.2);}
}
@keyframes flash-2 { 
    0%, 100% { background: rgba(0,255,255,1);
    box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(0,255,255,1);} 
    50% { background: rgba(0,255,255,$light-off-opacity);
    box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(0,255,255,0.2);}
}
@keyframes flash-3 { 
    0%, 100% { background: rgba(247,0,148,1);
    box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(247,0,148,1);} 
    50% { background: rgba(247,0,148,$light-off-opacity);
    box-shadow: 0px calc($globe-height / 6) calc($globe-width * 2) $globe-spread rgba(247,0,148,0.2);}
}


@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
  }
  
.snow {
    $total: 200;
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;
    background: white;
    border-radius: 50%;
  
    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: calc($random-x + $random-offset);
      $random-x-end-yoyo: calc($random-x + (calc($random-offset / 2)));
      $random-yoyo-time: calc(random_range(30000, 80000) / 100000);
      $random-yoyo-y: calc($random-yoyo-time * 100vh);
      $random-scale: calc(random(10000) * 0.0001);
      $fall-duration: calc(random_range(10, 30) * 1s);
      $fall-delay: calc(random(30) * -1s);
  
      &:nth-child(#{$i}) {
        opacity: calc(random(10000) * 0.0001);
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }
  
      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }
  
        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
}

.bat {
    $total: 50;
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: -1;
    background-image: url("https://media1.giphy.com/media/0xR7MUO0hJfWtco7C6/giphy.gif");
    background-size: contain;
    border-radius: 50%;
  
    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: calc($random-x + $random-offset);
      $random-x-end-yoyo: calc($random-x + (calc($random-offset / 2)));
      $random-yoyo-time: calc(random_range(30000, 80000) / 100000);
      $random-yoyo-y: calc($random-yoyo-time * 100vh);
      $random-scale: calc(random(10000) * 0.0001);
      $fall-duration: calc(random_range(10, 30) * 1s);
      $fall-delay: calc(random(30) * -1s);
  
      &:nth-child(#{$i}) {
        opacity: calc(random(10000) * 0.0001);
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }
  
      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }
  
        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
}

.heart {
    $total: 50;
    position: absolute;
    width: 25px;
    height: 25px;
    z-index: -1;
    background-image: url("../assets/heart.png");
    background-size: contain;
    border-radius: 50%;
  
    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: calc($random-x + $random-offset);
      $random-x-end-yoyo: calc($random-x + (calc($random-offset / 2)));
      $random-yoyo-time: calc(random_range(30000, 80000) / 100000);
      $random-yoyo-y: calc($random-yoyo-time * 100vh);
      $random-scale: calc(random(10000) * 0.0001);
      $fall-duration: calc(random_range(10, 30) * 1s);
      $fall-delay: calc(random(30) * -1s);
  
      &:nth-child(#{$i}) {
        opacity: calc(random(10000) * 0.0001);
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }
  
      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }
  
        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
}

.egg {
    $total: 50;
    position: absolute;
    width: 25px;
    height: 25px;
    z-index: -1;
    background-image: url("../assets/egg.png");
    background-size: contain;
    border-radius: 50%;
  
    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: calc($random-x + $random-offset);
      $random-x-end-yoyo: calc($random-x + (calc($random-offset / 2)));
      $random-yoyo-time: calc(random_range(30000, 80000) / 100000);
      $random-yoyo-y: calc($random-yoyo-time * 100vh);
      $random-scale: calc(random(10000) * 0.0001);
      $fall-duration: calc(random_range(10, 30) * 1s);
      $fall-delay: calc(random(30) * -1s);
  
      &:nth-child(#{$i}) {
        opacity: calc(random(10000) * 0.0001);
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }
  
      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
        }
  
        to {
          transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
        }
      }
    }
}
  