.log_mobile_content {
    height: calc(100vh - 100px);
    overflow-y: scroll;
    margin-bottom: 40px;
}

.log__button_width {
    width: 200px !important;
}

.log__tag_width {
    width: 400px !important;
}

.log__small_button_width {
    width: 40px !important;
    min-width: 40px !important;
}

.log__100_perc {
    width: 100% !important;
}

.log__min_height_490 {
    min-height: 430px;
    max-height: 430px;
}

.log__min_height_434 {
    min-height: 390px;
}

.log__stretch_items {
    margin-top: 5px;
    display: flex;
    align-items: stretch;
}

.log__flex_add {
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: hidden;
    overflow-x: hidden;
    align-items: stretch;
}

.log__container_add_block {
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 315px;
}
.log__container_add_block:nth-child(odd) {
    width: 100%;
}


.log__main_container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.log__main_stats{
    height: 1700px;
}

.log__container_inner_content {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: hidden;
}

.log__container_content {  
    width: 100%;
    min-height: 710px;
    border-radius: 6px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px;
}

.log__container_options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.log__container_buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.log__stats_cards {
    display: none;
}

.log__padding_sidebar {
    padding-left: 0px;
}

.log__margin_sidebar {
    margin-left: 64.5px;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.log__margin_left_small {
    margin-left: 5px;
}

.log__margin_right_small {
    margin-right: 5px;
}

/**********************************************************************************
*   Tablet layout
**********************************************************************************/
@media only screen and (min-width: 600px) {
    .log__padding_sidebar {
        padding-left: 65px;
    }
}

/**********************************************************************************
*   Desktop layout
**********************************************************************************/
@media only screen and (min-width: 1200px) {

    .log__container_content {  
        width: 95%;
        
    padding-left: 10px;
    padding-right: 10px;
    }
    
    .log__padding_sidebar {
        padding-left: 65px;
    }

    
    .log__stats_cards {
        min-height: 210px;
        width: 95%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .log__container_add_block:nth-child(odd) {
        width: 59%;
    }
    .log__container_add_block:nth-child(even) {
        width: 40%;
    }
}