.environment {
    border-radius: 10px;
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-evenly;    
    font-size: 15px;
}

.environment__search {
    width: 100%;
    position: absolute;    
    font-size: 15px;
    z-index: 100;
}

.environment__search_width {
    width: 100%;
    position: absolute;
}

.environment__search_input {
    margin-top: 8px;
    margin-right: 6px;
    margin-bottom: 0 !important;
    border-radius: 6px !important;
    width: 94%;
    margin-left: 3%;
}

.environment__search_button {
    margin-top: 15px !important;
    height: 100% !important;
    margin-bottom: 0 !important;
    border-radius: 6px !important;
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
    width: 94%;
    margin-left: 3% !important;
}

.environment__toggle_button {
    margin-top: 8px !important;
    /*height: 100% !important;*/
    margin-bottom: 0 !important;
    border-radius: 6px !important;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
}

.environment__card_body_container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    width: 100%;
    min-height: 10%;
    max-height: 100%;
    justify-content: space-evenly;
  }

  .environment__card_header_margin {
    margin-top: 140px;
  }

  .environment__card_body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    min-height: 10%;
    max-height: 100%;
    justify-content: center;
    align-content: flex-start;
    text-align: start;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .environment__card_dark:hover {
    background-color: #232B38;
  }

  .environment__card_light:hover {
    background-color: #E5E7EB;
  }

.environment__text_body {
    line-height: 1;
    margin-top: 5px;
    margin-bottom: 5px;
}

.environment__card {
    min-width: 350px;
    max-width: 350px;
    width: 350px;
    height: 200px;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 10px;
    position: relative;
}

.environment__card .icon {
    height: 100px;
    text-align: center;
    display: block;
    margin-top: 10px;
  }

.environment__card .small_icon {
    margin-top: 5px;
    margin-right: 5px;
}

.card_corner {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    padding: 0.8em;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card_corner_left {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    padding: 0.8em;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: orange;
}



.WL {
    background-color: red;
}

.VL {
    background-color: #FFD700;
}
  
/**********************************************************************************
*   Tablet layout
**********************************************************************************/
@media only screen and (min-width: 600px) {
    .environment__card {
        float: left;
        margin: 10px;
        min-width: 330px;
        max-width: 500px;
    }  

    .environment__search {
        width: 84%;
        position: absolute;    
        font-size: 15px;
    }

    .environment__search_width {
        width: 84%;
    }
    
    .environment__search_input {
        margin-top: 8px;
        margin-bottom: 0 !important;
        border-radius: 6px !important;
    }
}

/**********************************************************************************
*   Desktop layout
**********************************************************************************/
@media only screen and (min-width: 1200px) {
    .environment__card {
        float: left;
        margin: 10px;
        min-width: 330px;
        max-width: 350px;
    }    

    .environment__search_width {
        width: 87%;
    }

    .environment__search {
        width: 87%;
        position: absolute;    
        font-size: 15px;
        display: flex;
        flex-direction: row;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        padding-bottom: 8px;
    }
    
    .environment__search_input {
        margin-top: 8px;
        margin-bottom: 0 !important;
        border-radius: 6px !important;
        width: 90% !important;
    }

    
    .environment__search_button {
        width: 64px;
        max-width: 64px;
        margin-left: 10px !important;
        margin-top: 8px !important;
        margin-bottom: 10px !important;
        top: 5px !important;
        margin-right: 10px !important;
    }

    .environment__toggle_button {
        width: 64px;
        max-width: 64px;
        height: 36.5px !important;
        top: 5px !important;
    }

    .environment__card_body_container {
       margin-top: 60px;
      }
    .environment__card_header_margin {
        margin-top: 60px;
    }
    
   /* .stefan {
        width:52px;
        height:52px;
        cursor:url(https://my.softtouch.eu/static/media/stefan_cursor.png), auto;
    }*/
}