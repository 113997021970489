/*************************************************************************************
*
*                                   DEFINE COLORS
*
*************************************************************************************/
:root {
    --light_basic:          #F9FAFB;
    --light_accent:         #F3F4F6;
    --light_accent_darker:  #E5E7EB;
    --light_accent_darkest: #D1D5DB;
    --light_text:           #1F2937;
    --light_shadow:         #D1D5DB;

    /*--dark_basic:           #4B5563;
    --dark_accent:          #374151;
    --dark_accent_darker:   #1F2937;
    --dark_accent_darkest:  #111827;
    --dark_text:            #F9FAFB;
    --dark_text_accent:     #CACBCC;
    --dark_shadow:          #111827;*/

    --dark_basic:           #1B212B;
    --dark_accent:          #232B38;
    --dark_accent_darker:   #1B212B;
    --dark_accent_darkest:  #111827;
    --dark_text:            #F9FAFB;
    --dark_text_accent:     #CACBCC;
    --dark_shadow:          #111827;

    --sft_blue_darker:             #002C41;
    /* --sft_blue:             #28A9E0;  */

    --sft_blue:      #0071BB;
    /* --sft_blue_darker:      #1E9BD0;  */

    --sft_blue_text:        #C0E4DA;
    /* --sft_blue_text:        #F9FAFB;  */
}

span {
    font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important;
}

p {
    font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important;
}

a {
    font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important;
}

.font {
    font-family: 'Urbane Rounded', 'Ubuntu', sans-serif !important;
}

.light {
    background-color: var(--light_basic);
    color: var(--light_text);
}

.light_forced {
    background-color: var(--light_basic) !important;
    color: var(--light_text) !important;
}

.light_accent {
    background-color: var(--light_accent) !important;
    color: var(--light_text) !important;
}

.light_accent_darker {
    background-color: var(--light_accent_darker) !important;
    color: var(--light_text) !important;
}

.light_accent_darkest {
    background-color: var(--light_accent_darkest);
    color: var(--light_text);
}

.light_text { /* TODO: needed or not? */
    background-color: var(--light_text);
}

.light_shadow { /* TODO: background-color = correct? */
    background-color: var(--light_shadow);
}

.dark {
    background-color: var(--dark_basic);
    color: var(--dark_text) !important;
}

.dark_forced {
    background-color: var(--dark_basic) !important;
    color: var(--dark_text) !important;
}

.dark_accent {
    background-color: var(--dark_accent) !important;
    color: var(--dark_text) !important;
}

.dark_accent_darker {
    background-color: var(--dark_accent_darker) !important;
    color: var(--dark_text);
}

.dark_accent_darker {
    background-color: var(--dark_accent_darkest);
    color: var(--dark_text) !important;
}

.dark_text { /* TODO: needed or not? */
    color: var(--dark_text) !important;
}

.dark_shadow {
    background-color: var(--dark_shadow);
}

.sft_blue {
    background-color: var(--sft_blue);
    color: var(--sft_blue_text) !important;
}

.sft_blue_text {
    color: var(--sft_blue) !important;
}

.red {
    background-color: #b9251b !important;
    color: var(--dark_text) !important;
}

.error_text {
    color: #b9251b !important;
    font-weight: bold;
}

.green_text {
    color: #1bbe1b !important;
    font-weight: bold;
}

.green {
    background-color: #1bbe1b !important;
    color: var(--dark_text) !important;
}

.blue {
    background-color: var(--sft_blue) !important;
    color: var(--dark_text) !important;
}

.yellow {
    background-color: #F0AD4E !important;
    color: var(--dark_text) !important;
}

.pink {
    background-color: #FF8674 !important;
    color: var(--dark_text) !important;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding-left: 5px;
}

.bigger_text {
    font-size: 1.5rem;
}

.light_accent input:-webkit-autofill,
.light_accent input:-webkit-autofill:hover, 
.light_accent input:-webkit-autofill:focus, 
.light_accent input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--light_accent_darker) inset !important;
    -webkit-text-fill-color: var(--light_text) !important;
}

.dark_accent input:-webkit-autofill,
.dark_accentr input:-webkit-autofill:hover, 
.dark_accent input:-webkit-autofill:focus, 
.dark_accent input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--dark_accent_darker) inset !important;
    -webkit-text-fill-color: var(--dark_text) !important;

}


@media print {
    .no-print, .no-print *
    {
        display: none !important;
    }
}

/*************************************************************************************
*
*                                   BASIC LAYOUT
*
*************************************************************************************/

.App {

}


/*iframe {
    pointer-events: none;
}*/

label {
    width: 100%;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
}

.flex_center_column {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.flex_start_column {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
}

.app-content {
    display: flex;
    width: 100%;
    height: calc(100vh - 84px);
    justify-content: space-evenly;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 50px;
}

.container_content {  
    width: 100%;
    height: fit-content; 
    margin-top: 20px;
}

.container_split {
    flex: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.container_content_light {    
    -webkit-box-shadow: 0px 0px 5px 2px var(--light_accent_darkest);
    box-shadow: 0px 0px 5px 2px var(--light_accent_darkest);
}

.container_content_dark {    
    -webkit-box-shadow: 0px 0px 5px 2px var(--dark_accent_darker);
    box-shadow: 0px 0px 5px 2px var(--dark_accent_darker);
}

.container_content__mobile {  
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.no_background {  
    background-color: transparent !important;
}

.no-flex {
    display: inline;
}

.no-float {
    float: none !important;
}

.center {
    position: absolute;
    top: 0 !important; 
    bottom: 0 !important; 
    left: 0 !important; 
    right: 0 !important;
    margin: auto !important;
}

.center_fallback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.bold {
    font-weight: bold !important;
}

.code {
    padding: 2px 4px;
    font-size: 70%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
}

.code_trial {
    padding: 2px 4px;
    font-size: 70%;
    color: #f9f2f4;
    background-color: #FFA500;
    border-radius: 4px;
}

.code_premium {
    padding: 2px 4px;
    font-size: 70%;
    color: #f9f2f4;
    background-color: #bf9b30;
    border-radius: 4px;
}

.align_left {  
    float: left;
}

.align_right {
    float: right;
}

.text_left{
    text-align: start !important;
}

.text_right{
    text-align: end !important;
}

.text_center{
    text-align: center !important;
}


.primary_border{
    border: var(--sft_blue) solid 1px;
}

.primary_border_bottom{
    border-bottom: var(--sft_blue) solid 1px;
}

.light_border_bottom{
    border-bottom: var(--light_text) solid 1px;
}

.dark_border_bottom{
    border-bottom: var(--dark_text) solid 1px;
}

.button_100perc {
    width: 100%;
}


/* Responsive */
.resposive .react-colorful {
    width: auto !important;
}

._10perc {
    width: 10%;
}

._20perc {
    width: 20%;
}

._25perc {
    width: 25%;
}

._40perc {
    width: 40%;
}

._45perc {
    width: 45%;
}

._50perc {
    width: 50%;
}

._60perc {
    width: 60% !important;
}

._70perc {
    width: 70%;
}

._75perc {
    width: 75%;
}

._79perc {
    width: 79%;
}

._80perc {
    width: 80%;
}

._90perc {
    width: 90%;
}

._100perc {
    width: 100%;
}

.ultra_small_button {
    width: 20px;
}

.expanded{
    margin-left: 240px;
    width: calc(100% - 240px);
}

.not_expanded{    
    width: 100%;
}

.container_list__title {
    text-transform: lowercase;
    float: left;
    width: 100%;
}

.container_tabs_below_subtitle {
    width: calc(100%-10px) !important;
    margin-left: 5px !important;
    margin-right: 5px !important
}

.container_list__subtitle {
    text-transform: lowercase;
    float: none;
    width: calc(100%-10px);
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: var(--sft_blue);
}


.startScreen {
    margin-top: 100px;
}

.container_list__title::first-letter {
    text-transform: uppercase;
}

.container_list__subtitle::first-letter {
    text-transform: uppercase;
}

.width_300px {
    width: 300px !important;
}

.width_250px {
    width: 250px;
}

.width_200px {
    width: 200px;
}

.width_150px {
    width: 75px;
}

.width_140px {
    width: 140px;
    margin-left: 5px !important;
}

.width_120px {
    width: 120px;
    margin-left: 5px !important;
}

.width_100px {
    width: 100px;
}

.width_75px {
    width: 75px;
}

.max_width_210px {
    max-width: 210px;
}

.margin_none {
    margin: 0;
}

.margin_bottom_none {
    margin-bottom: 0 !important;
}
.margin_top_none {
    margin-top: 0 !important;
}

.margin_left__10perc {
    margin-left: 0%;
}
.padding_bottom__5px {
    padding-bottom: 5px;
}

.padding_bottom__10px {
    padding-bottom: 10px;
}

.padding_top__10px {
    padding-top: 10px;
}

.padding_top__30px {
    padding-top: 30px;
}

.padding_left__5px {
    padding-left: 5px;
}

.padding_left__10px {
    padding-left: 10px;
}

.padding_left__65px {
    padding-left: 65px;
}

.padding_right__5px {
    padding-right: 5px;
}

.padding_right__10px {
    padding-right: 10px;
}

.padding_right__15px {
    padding-right: 15px;
}

.padding_none {
    padding: 0px !important;
}

.margin_left__5px {
    margin-left: 5px;
}

.margin_left__65px {
    margin-left: 65px;
}

.margin_left__10px {
    margin-left: 10px;
}

.margin_right__5px {
    margin-right: 5px !important;
}

.margin_right__10px {
    margin-right: 10px !important;
}

.margin_right__20px {
    margin-right: 20px !important;
}

.margin_bottom_none {
    margin-bottom: 0px !important;
}

.margin_bottom_5px {
    margin-bottom: 5px !important;
}

.margin_bottom_10px {
    margin-bottom: 10px !important;
}

.margin_bottom_15px {
    margin-bottom: 15px !important;
}

.margin_bottom_20px {
    margin-bottom: 20px;
}

.margin_bottom_40px {
    margin-bottom: 40px;
}

.margin_top_none {
    margin-top: 0px !important;
}

.margin_top_5px {
    margin-top: 5px;
}

.margin_top_10px {
    margin-top: 10px !important;
}

.margin_top_15px {
    margin-top: 15px !important;
}

.margin_top_50px {
    margin-top: 50px !important;
}

.margin_top_80px {
    margin-top: 80px;
}

.margin_top_150px {
    margin-top: 150px;
}

.height_30px {
    height: 30px;
}

.height_100perc {
    height: calc(100vh - 12px) !important;
}

.width_max_500 {
    max-width: 500px;
    min-width: fit-content;
}

.no_right_margin {
    margin-right: 0px !important;
}

.colorpicker {
    width: 20% !important;
}

.delete_icon {
    color: var(--sft_blue) !important;
}

.detail {
    width: calc(100% - 80px - 80px) !important;
}

.wordbreak {
    word-break: break-all;
}

.border_radius {
    border-radius: 6px;
}

.border_radius_top {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.border_radius_bottom {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.recharts-tooltip-wrapper {
    color: black !important;
}

/**********************************************************************************
*   Alert
**********************************************************************************/


/**********************************************************************************
*   Tablet layout
**********************************************************************************/
@media only screen and (min-width: 600px) {
    ._80perc {
        width: 100%;
    }

    .width_150px {
        width: 150px;
    }

    ._100perc {
        width: 100%;
    }

    .container_content {  
        width: 100%;
        height: fit-content;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 5vh;
        border-radius: 6px;
        margin-top: 20px;
    }

    .not_expanded{    
        width: calc(100% - 52px);
    }
    
    .app-content {
        display: flex;
        width: 100%;
        height: calc(100vh - 84px);
        justify-content: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 64px;
    }

    .detail {
        width: calc(100% - 150px - 150px) !important;
    }

    .margin_left__10perc {
        margin-left: 0%;
    }
}

/**********************************************************************************
*   Desktop layout
**********************************************************************************/
@media only screen and (min-width: 1200px) {
    ._80perc {
        width: 80%;
    }

    .width_150px {
        width: 150px;
    }

    ._100perc {
        width: 100%;
    }

    .container_content {  
        width: 80%;
        height: fit-content;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 5vh;
        border-radius: 6px;
        margin-top: 20px;
    }

    .not_expanded{    
        width: calc(100% - 52px);
    }

    .app-content {
        display: flex;
        width: 100%;
        height: calc(100vh - 84px);
        justify-content: space-evenly;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 64px;
    }

    .detail {
        width: calc(100% - 150px - 150px) !important;
    }

    .margin_left__10perc {
        margin-left: 10%;
    }
}

/**********************************************************************************
*   Headers
**********************************************************************************/

/*h1 {
    
}*/

h2 {
    margin-top: 10px;    
    text-transform: lowercase;
}

h2::first-letter {
    text-transform: uppercase;
}

.h2_minus_button {
    width: calc(100% - 14px) !important;
}

h3 {
    width: 100%;
    margin-top: 10px;    
    text-transform: lowercase;
}

h3::first-letter {
    text-transform: uppercase;
}

.h3_minus_button {
    width: calc(100% - 150px) !important;
}

h4 {
    margin-bottom: 5px;
}

/**********************************************************************************
*   Button
**********************************************************************************/


.button_container {
    width: 150px;
    display: flex;
}

.button_container__button {
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
    margin-bottom: 10px !important;
}

.button_container__button_colors {
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
}

.button_inverted {
    border: var(--sft_blue) 1px solid !important;
    color: var(--sft_blue) !important;
}


.button_container__button_small {
    font-size: 15px;
    font-weight: bold;
    height: 30px;
    line-height: 1;
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
    margin-bottom: 10px !important;
}


.modal_button_container__button {
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
    margin-bottom: 10px !important;
    margin: 5px !important;
}

.modal_button_container_no_margin_5px_button_white {
    color: white !important;
    margin-bottom: 5px !important;
    margin: 0px;
    max-width: 210px;
    width: 100%;
    height: 30px;
}

.modal_button_container_no_margin_5px_button {
    color: var(--sft_blue_text) !important;
    margin-bottom: 5px !important;
    margin: 0px;
    max-width: 210px;
    width: 100%;
    height: 30px;
}

.modal_button_container_no_margin_5px_button_inverted {
    background-color: var(--sft_blue_text) !important;
    color: var(--sft_blue) !important;
    margin-bottom: 5px !important;
    margin: 0px;
    max-width: 210px;
    width: 100%;
    height: 30px;
}

.modal_button_container_no_margin__button {
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
    margin-bottom: 10px !important;
    margin: 0px;
}

.modal_button_container_no_margin_total__button {
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
    margin: 0px !important;
}

/**********************************************************************************
*   Input
**********************************************************************************/

input, select {
    width: 100%;
    padding: 7px;
    margin: 8px 0;
    font-size: 16px;
    height: 36px;
    display: inline-block;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px var(--light_shadow), 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);    
    font-size: 15px;
}

input[type="text"],  input[type="password"],  input[type="date"],  input[type="datetime"],  input[type="email"],  input[type="number"],  input[type="search"],  input[type="tel"],  input[type="time"],  input[type="url"],  select  {
    border:  none;
    display: inline;
    font-size:  16px;
    height:  auto;
    outline:  0;    
    padding: 10px;
    box-shadow:  0 1px 0 rgba(0, 0, 0, 0.03) inset;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         
    border-radius: 6px;
}

/**********************************************************************************
*   links
**********************************************************************************/

a {    
    text-decoration: none;
    color: var(--sft_blue);
}

a:hover {
    text-decoration: underline;
}

a:visited {
    color: var(--sft_blue);
}

a:active {
    color: var(--sft_blue);
}

/*************************************************************************************
*
*                                   FOOTER
*
*************************************************************************************/

.header__logo{
    width: 200px;
    padding-left: 16px;
    float: left;
    margin-top: 13px;
}

.header__button{
    min-width: 40px !important;
    margin-left: 10px !important;
}

.header_dark {
    background-color: var(--dark_accent) !important;
}

.header_light {
    background-color: var(--sft_blue) !important;
}

/**********************************************************************************
*   Tablet layout
**********************************************************************************/
@media only screen and (min-width: 600px) {
    .header__logo{
        height: 55px;
        float: left;   
        margin-top: 9px;
    }

    .header__button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        border-radius: 50% !important;
        height: 40px;
        width: 40px;
        border-color: transparent !important;
        margin-right: 10px;
    }
}

/**********************************************************************************
*   Desktop layout
**********************************************************************************/
@media only screen and (min-width: 1200px) {
    .header__logo{
        height: 55px;
        float: left;        
        margin-top: 10px;
    }

    .header__button{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        border-radius: 50% !important;
        height: 40px;
        width: 40px;
        border-color: transparent !important;
        margin-right: 10px;
    }
}

/*************************************************************************************
*
*                                   FOOTER
*
*************************************************************************************/

.footer{
    text-align: center;
    width: 100%;
    height: 20px;
    right: 0;
    left: 0;
    bottom: 0;
    position: fixed;
    margin-bottom: 0;
    z-index: 1000;
}

.footer__text{
    margin-bottom: 0px;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    position: fixed;
    text-align: center;
    width: 100%;
    right: 0px;
    left: 0;
    bottom: 0;
}

/*************************************************************************************
*
*                                   LOGIN
*
*************************************************************************************/



.MuiLoadingButton-loadingIndicator {
    color: var(--sft_blue) !important;
}

.MuiCircularProgress-root {
    width: 32px !important;
    height: 32px !important;
}





/*************************************************************************************
*
*                                   LEFT SIDEBAR
*
*************************************************************************************/
.full-width-screen {
    padding-top: 64px !important;
}
/*.left_sidebar {
    width: 100%;
    height: 100%;
    position: fixed;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    z-index: 99;
  }

.left_sidebar.collapsed {
    width: 52px;
}

.left_sidebar_item{
    list-style-type: none;
    height: 30px;
    font-size: 20px;
    text-decoration: none;
    padding: 10px 10px 10px 10px;   
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.left_sidebar_item_dark {    
    background-color: var(--dark_accent);
    color: var(--dark_text);
}

.left_sidebar_item_light {    
    background-color: var(--light_accent);
    color: var(--light_text);
}

.left_sidebar_item_dark:hover {
    background-color: var(--dark_accent_darker);
  }

.left_sidebar_item_light:hover {
    background-color: var(--light_accent_darker);
}

.left_sidebar_item_dark:active {
    background-color: var(--dark_accent_darker);
}

.left_sidebar_item_light:active {
    background-color: var(--light_shadow);
}

.left_sidebar_item__icon {
    width: 30px;
    min-width: 30px;  
    float: left;
    padding-top: 5px;
}
*/
.left_sidebar_item__image img {
    width: 30px !important;     
    height: 30px !important; 
    float: left;
}

.sidebar_items {
    padding-top: 50px;
}

.sidebar_header {
    margin-top: 20px;
    margin-left: 10px;
}

.sidebar_header_item {
    width: 100px;
    height: 20px;
}
/*
.left_sidebar_item__text {
    padding-left: 7px;
    float: left;
    font-size: 16px;
}

.left_sidebar_item__button {
    height: 40px;
    padding: 5px 10px 10px 10px; 
    margin: 0px;
    border-radius: 0px;
}

.left_sidebar_item__button_text {
    padding-top: 2px;
    padding-left: 7px;
    float: left;
}*/

/**********************************************************************************
*   Tablet layout
**********************************************************************************/
/*@media only screen and (min-width: 600px) {
    .left_sidebar {
        width: 255px;
        height: 100%;
        position: fixed;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }  
}*/

/**********************************************************************************
*   Desktop layout
**********************************************************************************/
/*@media only screen and (min-width: 1200px) {
    .left_sidebar {
        width: 255px;
        height: 100%;
        position: fixed;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }   
}*/
/*************************************************************************************
*
*                                   TABLE
*
*************************************************************************************/

.light_list_table {
    float: left;
    border-collapse: collapse;
    width: 100%;
    padding-right: -50px;
}

.dark_list_table {
    float: left;
    border-collapse: collapse;
    width: 100%;
    padding-right: -50px;
}
  
.light_list_table td, .light_list_table th {
    border: 0px solid #ddd;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

.dark_list_table td, .dark_list_table th {
    border: 0px solid #ddd;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

.light_list_table tr:nth-child(even) {
    background-color: var(--light_basic);
}

.dark_list_table tr:nth-child(even) {
    background-color: var(--dark_basic);
}

.light_list_table tr:nth-child(odd) {
    background-color: var(--light_accent);
}

.dark_list_table tr:nth-child(odd) {
    background-color: var(--dark_accent);
}

.light_list_table tr:hover {
    background-color: var(--light_accent_darker);
}

.dark_list_table tr:hover {
    background-color: var(--dark_accent_darker);
}

.light_list_table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--light_accent_darkest);
    color: var(--light_text);
}

.dark_list_table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--dark_accent_darkest);
    color: var(--dark_text);
    }

.list_table__row_link {
    
    word-break: break-all;    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}

/*************************************************************************************
*
*                              HORIZONTAL TOGGLE SWITCH
*
*************************************************************************************/

.switch {
    position: relative;
    display: inline-block;
    /*margin-left: 18px;*/
    width: 60px;
    height: 34px;
    z-index: 10;
}
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
    z-index: 10;
}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    z-index: 10;
    max-width: 60px;
    max-height: 40px;
}

  .slider_dark {
      background-color: var(--dark_accent_darkest);
}
  .slider_light {
    background-color: var(--light_accent_darkest);
}
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    -webkit-transition: .2s;
    transition: .2s;
    z-index: 10;
}

  .slider_dark:before {
    background-color: var(--dark_text);
}

  .slider_light:before {
    background-color: var(--dark_text);
}

  input:checked + .slider {
    background-color: var(--sft_blue);
    z-index: 10;
}
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--sft_blue);
    z-index: 10;
}
  
input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    z-index: 10;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    z-index: 10;
}

.slider.round:before {
    border-radius: 50%;
    z-index: 10;
}


/**********************************************************************************
*   Tablet layout
**********************************************************************************/
@media only screen and (min-width: 600px) {
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        z-index: 10;
    }
}

/**********************************************************************************
*   Desktop layout
**********************************************************************************/
@media only screen and (min-width: 1200px) {
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
        z-index: 10;
    }
}



/*************************************************************************************
*
*                                     CONTAINER
*
*************************************************************************************/
.container_list{
    width: 100%;
    overflow: hidden;
}

.container_list__title {
    margin: auto;
    width: 100%;
    text-align: center;
    overflow: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
    box-shadow: none;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 15px;
    /* background-color: rgb(248, 248, 248); */
}

.container_list__title input {
    width: 600px;
    margin-top: 0px !important;
    font-size: 24px !important;
    font-weight: bold;
    text-align: center;
    border-bottom: none;
    border-top: none ;
    border-left: none ;
    border-right: none ;
    border-bottom: none;
    box-shadow: none;
    font-size: 15px;
}

.container_list__title h2 {
    width: 100%;
    font-size: 24px !important;
    font-weight: bold;
    text-align: center;
    border-bottom: none;
    border-top: none ;
    border-left: none ;
    border-right: none ;
    border-bottom: none;
    box-shadow: none;
    font-size: 15px;
}



.list_item {
    font-size: 15px !important;
}

.container_list__title input:focus, textarea:focus, select:focus{
    outline: none;
}

.textarea {
    width: 100%;
    border:  none;
    display: inline;
    font-size:  16px;
    height:  auto;
    margin:  0;
    outline:  0;
    padding: 15px;
    box-shadow:  0 1px 0 rgba(0, 0, 0, 0.03) inset;
    margin-bottom:  25px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    border-radius: 6px;
}

fieldset  {
    margin-bottom:  30px;
    border:  none;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.number  {
    background-color:  rgb(40,170,225);
    color:  #fff;
    height:  30px;
    width:  30px;
    display:  inline-block;
    font-size:  0.8em;
    margin-right:  4px;
    line-height:  30px;
    text-align:  center;
    text-shadow:  0 1px 0 rgba(255, 255, 255, 0.2);
    border-radius:  100%;
}

legend  {
    font-size:  1.4em;
    margin-bottom:  10px;
}

.toggleButtonSmall {
    margin: 0px;
    width: 25px !important;
    min-width: 25px !important;
    height: 25px;
    position: relative;
    overflow: hidden;
    color: #fff;
    background-color: #1bbe1b;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.25rem;
    outline: 0;
    border: 0;
    border-radius: 0.15rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3); /* black with 30% opacity */
    cursor: pointer;
  }

  .licence_header {
      width: calc(100%-10px) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 5px;
      margin-right: 5px;
  }

  .txt_search_licence {
    width: 99.3% !important;
    margin-left: 5px;
    margin-right: 5px;
  }

  .licence_card_container {
    text-align: start;
    width: 95%;
    padding: 5px;
    margin: 5px;
    vertical-align: center;
    overflow: auto;
    display: inline-block;
    z-index: 1;   
    justify-content: center;
    align-items: center;
    padding: auto;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}  

.general_card_container {
    padding: 10px;
    margin: 5px;
    vertical-align: center;
    overflow: auto;
    display: inline-block;
    z-index: 1;   
    justify-content: center;
    align-items: center;
    padding: auto;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}  

.card_container {
    width: 170px;
    height: 170px;
    text-align: center;
    padding: 5px;
    margin: 5px;
    /*vertical-align: center;*/
    overflow: auto;
    border-radius: 25px;
    display: inline-block;
    font-weight: 600;
    z-index: 1;   
}  

.card_container_dark {
    background-color: var(--dark_accent) !important;
    color: var(--dark_text) !important;
}

.card_container_light {
    background-color: var(--light_accent) !important;
    color: var(--light_text) !important;
}

.card_container_dark:hover {
    background-color: var(--dark_accent_darkest) !important;
}

.card_container_light:hover {
    background-color: var(--light_accent_darker) !important;
}

.card_container_center {
    width: 170px;
    height: 170px;
    padding-right: 25px;
    margin: auto;
    padding-bottom: 50px;
}  

.module_card_detail {
    width: 500px;
}

.select-selected {
    background-color: DodgerBlue;
  }


.Toastify__progress-bar-theme--light{
    background: var(--sft_blue) !important;    
}

.Toastify__progress-bar-theme--dark{
    background: var(--sft_blue) !important;    
}

.Toastify__close-button {
    width: 20px;
}

.Mui-selected{
    color: var(--sft_blue) !important;
}

.MuiLoadingButton-loadingIndicator {
    position: static !important;
}

.MuiTypography-body1 {    
    
}

.sidebaritem_item_dark:hover{
    background-color: pink;
}

.sidebaritem_item_light:hover{
    background-color: var(--light_accent_darkest);
}

.sidebaritem_item__img {
    min-width: 32px !important;
}

.sidebaritem_item__title {
    padding-left: 10px;
}

.licence__container {
    width: 100%;
    max-width: 100%;
}

.licence__qr {
    height: 220px;
    width:  220px;
    display: block;
    margin: 0 auto;
}

.licence__client {
    width:  340px;
    display: block;
    margin: 0 auto;
}

.licence__enivronment {
    margin-top: 10px;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 1px;
}

.licence__input {
    text-align: 'end' !important;
}

/**********************************************************************************
*   Tablet layout
**********************************************************************************/
@media only screen and (min-width: 600px) {
    .licence__container {
        width: 100%;
    }

    .licence__qr {
        height: 220px;
        width:  220px;
        display: block;
        margin: 0 auto;
    }

    .licence__client {
        margin-top: 140px !important;
        width:  340px;
        display: block;
        margin: 0 auto;
    }

    .licence__enivronment {
        margin-top: 20px;
        width: 70%;
        margin-left: 120px;
    }

    .licence_header {
        width: calc(100%-10px) !important;
        display: flex;
        justify-content: space-between;
        align-items: space-between;
        flex-direction: row;
    }

    .licence_card_container {
        width: fit-content;
        height: 260px;
        text-align: start;
        padding: 5px;
        margin: 5px;
        vertical-align: center;
        overflow: auto;
        display: inline-block;
        z-index: 1;   
        justify-content: center;
        align-items: center;
        padding: auto;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    }  
}

/**********************************************************************************
*   Desktop layout
**********************************************************************************/
@media only screen and (min-width: 1200px) {
    .licence__container {
        width: 100%;
    }

    .licence__qr {
        height: 220px;
        width:  220px;
    }

    .licence__client {
        margin-top: 0px !important;
        width:  100%;
        display: block;
        margin: 0 auto;
        position: relative;
    }

    .licence__enivronment {
        margin-top: 20px;  
        width: 100%;
        margin-left: 0px;     
    }

    .licence_header {
        width: calc(100%-10px) !important;
        display: flex;
        justify-content: space-between;
        align-items: space-between;
        flex-direction: row;
    }

    .licence_card_container {
        width: fit-content;
        height: 260px;
        text-align: start;
        padding: 10px;
        margin: 5px;
        vertical-align: center;
        display: inline-block;
        z-index: 1;   
        justify-content: center;
        align-items: center;
        overflow: hidden;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    }  
}


/*************************************************************************************
*
*                                     SHIPPING
*
*************************************************************************************/

.shipping_field_full_width{
    width: 100%;
    margin-bottom: 10px !important;
}

.shipping_field {
    margin-bottom: 10px !important;
    margin-right: 1% !important;
}

.shipping_fields {
    padding-bottom: 140px !important;
}

.shipping_field_input {
    border-radius: 4px;
}

.MuiPhoneNumber-flagButton {
    padding-left: 5px !important;
}

.shipping {
    /*height: 100% !important;*/
    height: fit-content !important;    
    overflow-x: auto;
    overflow-y: auto;
}

.shipping_content_small {
    /*height: 100% !important;*/
    height: calc(100vh - 36px) !important;
    overflow-x: auto;
    overflow-y: auto;
    margin-bottom: 0px !important;
}

.shipping_bottom_20px {
    bottom: 20px !important;
}

.shipping_width_35perc {
    width: 35% !important;
}
.shipping_width_49perc {
    width: 49% !important;
}

.shipping_width_50perc {
    width: 50% !important;
}

.shipping_width_64perc {
    width: 64% !important;
}

.shipping_button_move_up {
    top: -40px !important;
}

.shipping_float_right {
   float: right;
}
.shipping_float_none {
    float: left;
}
.shipping_provider_card_light {
    float:"left";
    margin: 20px;
    width: 190px;
    height: 230px;
    text-align: center;
}

.shipping_provider_card_dark {
    float:"left";
    margin: 20px;
    width: 190px;
    height: 230px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;
    border: 2px solid var(--dark_accent_darker);
    border-radius: 6px;
    background-color: var(--dark_accent_darkest);
}

.shipping_provider_card_selected_light {
    float:"left";
    margin: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    width: 190px;
    height: 230px;
    border: 2px solid var(--sft_blue);
    border-radius: 6px;
    background-color: var(--light_accent);
    text-align: center;
}

.shipping_provider_card_selected_dark {
    float:"left";
    margin: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    width: 190px;
    height: 230px;
    border: 2px solid var(--sft_blue);
    border-radius: 6px;
    background-color: var(--dark_accent_darkest);
    text-align: center;
}

.shipping_options {
    width: 100%;
}

.shipping_option {
    margin-top: 20px;
    margin-top: 10px;
    height: 34px;
    min-width: 300px;
    margin-right: 55px;
    float: left;
    border-width: 1px;
    border-style: solid;    
    border-color: var(--light_shadow);
    border-radius: 6px 17px 17px 6px;
    padding-left: 5px;
}

.shipping_option_full_width {
    margin-top: 10px;
    height: 34px;    
    min-width: 250px;
    margin-right: 3px;
    border-width: 1px;
    border-style: solid;    
    border-color: var(--light_shadow);
    border-radius: 6px 17px 17px 6px;
    padding-left: 5px;
}

.shipping_option_text{
    vertical-align: middle;
    line-height: 32px;
    float: left;
    width: 200px;
}

.shipping_toggle{
    float: right !important;   
    width: 20px;
    padding-right: 45px !important;
}

.shipping_toggle_full_width{
    float: right !important;   
    width: 20px;
    padding-right: 23px;
}

.shipping_button_high{
    height: 49px;
    width: 160px;
}

.shipping_country{
    height: 47px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/*.shipping_label {
    width: 90%;
}*/
.fa-check-circle {
    background: #fff;
    border-radius: 50%;
}

.shaker  {
    animation: shake 0.52s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@media only screen and (min-width: 1200px) {
    .shipping_toggle{        
        padding-right: 23px;
        margin-right: 15px !important;
    }
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }



/*************************************************************************************
*
*                            Small card with image
*
*************************************************************************************/


.small__card {
    min-width: 100px;
    max-width: 100px;
    width: 100px;
    height: 85px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin: 10px;
    position: relative;
    float: left;
    margin-top: 20px;
}

.small__card_body_container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    width: 100%;
    min-height: 10%;
    max-height: 100%;
    justify-content: space-evenly;
}

.small__card_light:hover {
    background-color: var(--light_accent_darker) !important;
}

.small__card_dark:hover {
    background-color: var(--dark_accent_darkest) !important;
}


/*************************************************************************************
*
*                                       Manuals
*
*************************************************************************************/
.manuals_application_icons{
    width: 100%;
}

.manuals_application_headers{

    width: 100%;
    height: 500px !important;
}


.manual__search_input {
    margin-top: 8px;
    margin-right: 6px;
    margin-bottom: 0 !important;
    border-radius: 0px !important;
}

.small_icon {
    height: 30px;
    width: 30px;
}