
.input-file {
    position: relative;
    display: block;
    font-weight: 400;
  }
  
.input-file .button {
    position: absolute;
    top: 17px;
    right: 7px;
    float: none;
    height: 22px;
    margin: 0;
    padding: 0 14px;
    font-size: 13px;
    line-height: 22px;
    background-color: var(--sft_blue);
    opacity: .8;
    transition: opacity .2s;
    -o-transition: opacity .2s;
    -ms-transition: opacity .2s;
    -moz-transition: opacity .2s;
    -webkit-transition: opacity .2s;
    outline: 0;
    border: 0;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .input-file .button input {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    font-size: 30px;
    cursor: pointer;
    opacity: 0;
  }

  .excel__add_button {
    width: 200px;
    max-width: 50%;
    margin-left: 10px !important;
    margin-top: 8px !important;
    margin-bottom: 10px !important;
    top: 5px !important;
    margin-right: 10px !important;
    border-radius: 6px !important;
    background-color: var(--sft_blue) !important;
    color: var(--sft_blue_text) !important;
}

.excel__delete_button {
    width: 200px;
    max-width: 50%;
    margin-left: 10px !important;
    margin-top: 8px !important;
    margin-bottom: 10px !important;
    top: 5px !important;
    margin-right: 10px !important;
    border-radius: 6px !important;
    background-color: #b9251b !important;
    color: var(--sft_blue_text) !important;
}
  
blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}

blockquote .small, blockquote footer, blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
}

blockquote .small:before, blockquote footer:before, blockquote small:before {
    content: '\2014 \00A0';
}

.code_excel {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
}

.excel_grid {
    min-height: 300px;
}

/*************************************************************************************
*
*                                       Excel
*
*************************************************************************************/

.ExcelTable2007 {
	border: 1px solid #B0CBEF;
	border-width: 1px 0px 0px 1px;
	font-size: 11pt;
	font-family: Calibri;
	font-weight: 100;
	border-spacing: 0px;
	border-collapse: collapse;
	padding: 10px;
}

.ExcelTable2007 TH {
	background-image: url(excel-2007-header-bg.gif);
	background-repeat: repeat-x; 
	font-weight: bold;
	font-size: 14px;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	height: 17px;
	line-height: 17px;
}

.ExcelTable2007 TD {
	border: 0px;
	background-color: white;
	padding: 0px 4px 0px 2px;
	border: 1px solid #D0D7E5;
	border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
	border: 0px;
	background-color: white;
	font-weight: bold;
}

.ExcelTable2007 TD.heading {
	background-color: #E4ECF7;
	text-align: center;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	font-weight: bold;
}

.ExcelTable2007 TH.heading {
	background-image: url(excel-2007-header-left.gif);
	background-repeat: none;
}

