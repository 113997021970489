.quiz_logo {
    margin-top: 10px;
    margin-bottom: 20px;
}

.quiz_answer_button_selected {
    background-color: var(--sft_blue_darker) !important;
    color: var(--sft_blue_text) !important;
}

/* Mobile */
.mobile_card {
    width: 95%;
    height: 75%;
}

.quiz_answer_button {
    width: 49% !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    height: 50px !important;
}


/* Tablet */
@media only screen and (min-width: 600px) {
    .mobile_card {
        width: 60%;
        height: 80%
    }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
    .mobile_card {
        width: 40%;
        height: 70%;
    }
}

/* Animation */
.blink-1 {
	-webkit-animation: blink-1 0.6s both;
	        animation: blink-1 0.6s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-2-27 14:29:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
 @-webkit-keyframes blink-1 {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }
  @keyframes blink-1 {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }
  